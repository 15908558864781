import * as React from 'react';
import { IWPhotoProps } from '../WPhoto.types';
import { TRANSPARENT_BASE64_PIXEL } from '../constants';
import { replaceCompIdPlaceholder } from '../../../core/commons/utils';

const WPhoto: React.FC<IWPhotoProps> = props => {
  const {
    skin: WPhotoClass,
    id,
    uri,
    alt,
    name,
    width,
    height,
    displayMode,
    focalPoint,
    filterEffectSvgString,
    maskDataElementString,
    filterEffectSvgUrl: originalFilterEffectSvgUrl,
    quality,
    crop,
  } = props;

  const imageProps = {
    containerId: id,
    uri: uri || TRANSPARENT_BASE64_PIXEL,
    alt,
    name,
    width,
    height,
    displayMode,
    focalPoint,
    filterEffectSvgString:
      filterEffectSvgString &&
      replaceCompIdPlaceholder(filterEffectSvgString, id),
    maskDataElementString:
      maskDataElementString &&
      replaceCompIdPlaceholder(maskDataElementString, id),
    quality,
    crop,
  };
  const filterEffectSvgUrl = originalFilterEffectSvgUrl
    ? replaceCompIdPlaceholder(originalFilterEffectSvgUrl, id)
    : undefined;
  return (
    <WPhotoClass
      {...props}
      imageProps={imageProps}
      filterEffectSvgUrl={filterEffectSvgUrl}
    />
  );
};

export default WPhoto;
