import React, { FunctionComponent } from 'react';
import { TranslationGetter } from '@wix/editor-elements-types';
import { MediaContainerCompProps } from '../MediaContainer.types';
import {
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../../../Container/viewer/shared/constants';
import MediaContainerContent from './MediaContainerContent';
import styles from './styles/MediaContainer.scss';

const noop = () => {};

const getAriaLabel = (translate: TranslationGetter | undefined) => {
  return translate
    ? translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY, ARIA_LABEL_DEFAULT)
    : ARIA_LABEL_DEFAULT;
};

const MediaContainer: FunctionComponent<MediaContainerCompProps> = props => {
  const {
    id,
    children,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    hasHoverInteraction,
    translate,
  } = props;

  const a11yAttr = hasHoverInteraction
    ? { tabindex: 0, role: 'region', 'aria-label': getAriaLabel(translate) }
    : {};

  return (
    <div
      id={id}
      className={styles.mediaContainer}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...a11yAttr}
    >
      <MediaContainerContent {...props}>{children}</MediaContainerContent>
    </div>
  );
};

export default MediaContainer;
