import * as React from 'react';
import { TestIds } from '../constants';
import { LinkProps, ILinkImperativeActions } from '../Link.types';
import { activateBySpaceButton } from '../../../core/commons/a11y';

const Link: React.ForwardRefRenderFunction<
  ILinkImperativeActions,
  LinkProps
> = (props, ref) => {
  const {
    href,
    target,
    rel,
    className = '',
    children,
    linkPopupId,
    anchorDataId,
    anchorCompId,
    tabIndex,
    dataTestId = TestIds.root,
    title,
    onClick,
    activateBySpaceButton: shouldActivateBySpaceButton = true,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-pressed': ariaPressed,
    'aria-expanded': ariaExpended,
    'aria-haspopup': ariaHasPopup = linkPopupId
      ? 'dialog'
      : props['aria-haspopup'],
  } = props;

  const linkRef = React.createRef<HTMLAnchorElement>();

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        linkRef.current?.focus();
      },
      blur: () => {
        linkRef.current?.blur();
      },
    };
  });

  return href !== undefined ? (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <a
      data-testid={dataTestId}
      data-popupid={linkPopupId}
      data-anchor={anchorDataId}
      data-anchor-comp-id={anchorCompId}
      href={href || undefined}
      target={target}
      role={linkPopupId ? 'button' : undefined}
      rel={rel}
      className={className}
      onKeyDown={
        shouldActivateBySpaceButton ? activateBySpaceButton : undefined
      }
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-pressed={ariaPressed}
      aria-expanded={ariaExpended}
      aria-haspopup={ariaHasPopup}
      title={title}
      onClick={onClick}
      ref={linkRef}
      tabIndex={linkPopupId ? 0 : tabIndex}
    >
      {children}
    </a>
  ) : (
    <div
      data-testid={dataTestId}
      className={className}
      tabIndex={tabIndex}
      aria-haspopup={ariaHasPopup}
      title={title}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default React.forwardRef(Link);

export const isValidLink = (link?: LinkProps): boolean => {
  return Boolean(link && (link.href || link.linkPopupId));
};
